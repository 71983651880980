@use '../../basic/' as *;

.Partners{
    display: grid;
    gap: pg(600);
    .container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: wrap;
        gap: pg(600);
        justify-items: center;
        justify-content: center;
        img{
            max-width: 200px;
            max-height: 75px;
            // width: 100%;
            height: auto;
        }
    }
}
