@use '../../basic/' as *;

.projects {
    .page_head {
        height: calc(100vh - 100px);
        @include mq(md) {
            height: calc(100vh - 150px);
        }
        @include mq(lg) {
            height: calc(100vh - 100px);
        }
    }
    .page_body {
        max-width: initial;
        width: 100%;
        z-index: 2;
        background: clr(primary, bg-100);
        align-content: start;
        justify-content: center;

        .container {
            max-width: 800px;
        }
    }
}
