@use '../../basic/' as *;

.Header {
    background: none;
    padding: 0;
    height: calc(100vh - 100px);
    div {
        display: grid;
        align-items: start;
        align-content: space-between;
        font-weight: bold;
        color: clr(primary, bg-100);
        .title {
            background: clr(primary, shading-100);
            text-align: center;
            padding: pg(400) pg(200);
            font-size: fs(400);
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            text-transform: uppercase;

            @include mq(sm) {
                font-size: 2em;
            }
        }

        .arrow_down {
            margin: 10em 0;
            justify-self: center;
            height: 50px;
            width: 88px;
            background: url("../../../pics/down.jpg");
            border: none;
            outline: none;
            cursor: pointer;
            transition: filter 150ms linear;
            filter: drop-shadow(0px 0px 10px clr(primary, shadow-100));

            &:hover {
                filter: drop-shadow(0px 0px 10px clr(primary, bg-200));
            }
        }
    }
}
