@use '../../basic/' as *;

.Toggle{
    z-index: 3;
    padding: .8rem;
    display: grid;
    align-content: end;
    @include mq(md){
        display: none;
    }
    div{
        width: 3rem;
        height: 1.5rem;
        position: relative;
        cursor: pointer;
    }
    span{
        position: absolute;
        border-radius: 0px;
        background: clr(primary, fg-100);
        height: 4px;
        width: 100%;
        left: 50%;
        transition: .25s ease-in-out;
        &:nth-child(1), &:nth-child(2){
            top: 50%;
            transform: translate(-50%, -50%) rotate(0deg);
        }
        &:nth-child(3){

            top:0%;
            transform: translate(-50%, 0%);
        }
        &:nth-child(4){
            top:100%;
            transform: translate(-50%, -100%);
        }
    }
    &.open{
        span{
            &:nth-child(1){
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-child(2){
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &:nth-child(3), &:nth-child(4){
                top: 50%;
                transform: translate(-50%, -50%);
                width: 0%;
            }
        }
    }
}
